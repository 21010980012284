import styled from 'styled-components'
const OverlayStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  .ContenedorModal{
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 10px;
    padding: 2rem;
    max-width: 300px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  }
  .Encabezado{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e1e1e1;
  }
  .BotonCerrar{
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    border: none;
    background: none;
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius:6px;
    color: #00838f;
    &:hover {
      background: #26c6da;
    }
    svg{
      width: 100%;
      height: 100%;
    }
  }
  .BotonAceptar{
    margin-top: 20px;
    padding: 10px 20px;
    border-radius:100px;
    color: #fff;
    background-color: rgb(125 41 125);
    border: none;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    transition: .3s ease all;
    /* &:hover{
      background-color: #26c6da;
    } */
  }
  .CentrarBoton{
    text-align: center;
  }
  p{
    padding-top: 1rem;
    font-size: 1rem;
    margin-bottom: 1rem;
    text-align: center;
    font-family:ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }
  h1{
    font-size: 1.2rem;
    margin-bottom: 1rem;
    text-align: center;
    font-family:ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }
`;


export default OverlayStyle