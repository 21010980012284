import styled from "styled-components";

const FormStyle = styled.form`
  background-color: #fff;
  padding-left:64px;
  padding-right:64px;
  /* border-radius: 6px; */
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  font-family:ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  h1 {
    font-size: 32px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 16px;
    color: rgb(125 41 125);
    text-align: center;
  }
  .ContenedorPregunta{
    flex: 1 1 0%;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 0px;
    
    label{
      font-size: 20px;
    line-height: 28px;
      color:rgb(107,114,128);
      font-weight: bold;
    }
    span{
      font-size: 12px;
      padding: 4px;
      line-height: 16px;
      color:rgb(248, 113, 113);
      display:none
    }
    input {
      width: 100%;
      padding: 14px;
      margin-bottom: 0px;
      margin-top  : 10px;
      border: 2px solid #e1e1e1;
      border-radius: 5px;
      box-sizing: border-box;
      :placeholder-shown{
        font-family:ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-weight: 400;
      }
   }
    textarea{
      width: 100%;
      padding: 10px;
      margin-bottom: 0px;
      margin-top  : 10px;
      border: 2px solid #e1e1e1;
      width: 100%;
      border-radius: 5px;
      resize: none;
      box-sizing: border-box;
      min-height:100px;
      :hover{
        border: 2px solid rgb(125 41 125);
      }
    }
  }
  button{
    width: 100%;
    padding:14px;
    margin-top: 10px;
    margin-bottom: 0px;
    border: none;
    background-color: rgb(125 41 125);
    color:white;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
    margin-top: 16px;
    margin-bottom: 28px;
    border-radius: 100px;
  }

/* Elimina el scroll de los textareas */
textarea::-webkit-scrollbar{
    width: 2px;
}
input:invalid[focused="true"]{
    border:1px solid red
}
input:invalid[focused="true"] ~ span{
    display: block;
}
textarea:invalid[focused="true"]{
    border:1px solid red
}
textarea:invalid[focused="true"] ~ span{
    display: block;
}

//Al seleccionar en el input alguna opcion queda en blanco
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
`;

export default FormStyle;
