import * as config from "../config.js"

const postData = async function (data) {
    const url = config.APIUrl + "/api/v1/form"
    try {
        let params = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(data)

        }
        let response = await fetch(url, params)
        await response.json()
        // console.log('Se mandaron los datos correctamente', json)
        return true;
    } catch (e) {
        console.error("Hubo un problema al mandar los datos: " + e);
        return false;
    }
};

const getData = async function (path) {
    const url = `${config.APIUrl}/${path}`
    let params = {
        method: 'GET',
    }
    try {
        let response = await fetch(url, params)
        let json = await response.json()
        return json
    } catch (error) {
        return error
    }
};
function fetchDesplegable(data_APIRoute) {
    return fetch(config.APIUrl + data_APIRoute)
        .then(function (response) {
            return response.json();
        })
        .then(function (myJson) {
            return myJson;
        });
}
//Funcion para leer archivo json en public
function fetchLocalData(name) {
    fetch(name + ".json", {
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
    })
        .then(function (response) {
            return response.json();
        })
        .then(function (myJson) {
            // console.log(myJson)
            return myJson;
        });
};
export { postData, getData, fetchDesplegable, fetchLocalData }