import FormStyle from "./styles/FormStyle.js";
import Overlay from "./Overlay.jsx";
import { useEffect, useState } from "react";
import DomType from "./domtype/DomType.jsx";
import { postData} from "../services/comms.js";
const Formulario = () => {
  //Leo el json que contiene la estructura del formulario
  const [estructura, setEstructura] = useState({});
  const getEstructura = () => {
    fetch("estructura.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setEstructura(myJson);
      });
  };
  useEffect(() => {
    getEstructura();
  }, []);

  //Establezo que todos los campos del formulario esten vacios
  const [values, setValues] = useState(() => {
    const obj = {};
    estructura.formulario?.map((item) => {
      obj[item.name] = "";
      return true;
    });
    return obj;
  });

  //Cuando se escribe en el input se ejecuta esta funcion y guarda los valores en values
  const onChange = (e) => {
    if (e.target.getAttribute("domtype") === "textarea") {
      autoSizeHeight(e);
    }
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };
  const onChangeSelect = (e) => {
    setValues({
      ...values,
      [e.name]: e.label,
    });
  };
  //Redimension la altura de textarea automaticamente
  const autoSizeHeight = (e) => {
    e.target.style.height = "auto";
    e.target.style.height = e.target.scrollHeight + "px";
  };

  //Cuando se envia el formulario se ejecuta esta funcion
  const handleSubmit = async (e) => {
    // console.log(values);
    e.preventDefault();
    await postData(values);
    setShowOverlay(true);
  };
  //Maneja el Overlay
  const [showOverlay, setShowOverlay] = useState(false);

  return (
    <>
      <FormStyle onSubmit={handleSubmit}>
        <h1>Consulta Web Gratuita</h1>
        {estructura.formulario?.map((item) => {
          const { label, error_message } = item;
          return (
            <div className="ContenedorPregunta" key={item.id}>
              <label>
                <div>{label}</div>
              </label>
              <DomType
                {...item}
                onChange={onChange}
                onChangeSelect={onChangeSelect}
              />
              <span>{error_message}</span>
            </div>
          );
        })}
        <button>Enviar</button>
      </FormStyle>
      <Overlay showOverlay={showOverlay} setShowOverlay={setShowOverlay} estructura={estructura}/>
    </>
  );
};

export default Formulario;
