// Se encarga de renderizar el tipo de input (input, textarea,)
import React from "react";
import Desplegable from "./Desplegable.jsx";
import InputBox from "./InputBox.jsx";
import InputBoxVariable from "./InputBoxVariable.jsx";
function DomType(props) {
  const { onChange, onChangeSelect, ...inputProps } = props;
  //INPUT
  if (props.domtype === "input") {
    return (
      <>
        <InputBox {...inputProps} onChange={onChange} />
      </>
    );
  }
  //TEXTAREA
  else if (props.domtype === "textarea") {
    return (
      <>
        <InputBoxVariable {...inputProps} onChange={onChange} />
      </>
    );
  }
  //DESPLEGABLE
  else if (props.domtype === "select") {
    return (
      <>
        <Desplegable {...inputProps} onChange={onChangeSelect} />
      </>
    );
  }
}
export default DomType;
