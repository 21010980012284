import React, { useState } from 'react'

function InputBox(props) {
  const { onChange, ...inputProps } = props;
  const [focused, setFocused] = useState(false);
  const handleFocus = (e) => {
    setFocused(true);
  };
  return (
    <input
      {...inputProps}
      onChange={onChange}
      onBlur={handleFocus}
      focused={focused.toString()}
    />
  );
}

export default InputBox