import { useState } from "react";

function InputBoxVariable(props) {
  const { onChange, ...inputProps } = props;
  const [focused, setFocused] = useState(false);
  const handleFocus = (e) => {
    setFocused(true);
  };
  return (
    <textarea
      {...inputProps}
      onChange={onChange}
      onBlur={handleFocus}
      focused={focused.toString()}
    />
  );
}

export default InputBoxVariable;
