import { useEffect, useState } from "react";
import Select from "react-select";
import {fetchDesplegable} from "../../services/comms.js";
function Desplegable(props) {
  const { onChange, data_apiroute, desplegableoptions, ...inputProps } = props;
  const [data, setData] = useState(null);
  useEffect(() => {
    if (data_apiroute !== "") {
      fetchDesplegable(data_apiroute).then(function (res) {
        setData(
          res.map((item) => {
            return {
              name: inputProps.name,
              value: item.name,
              label: item.name,
            };
          })
        );
      })
      .catch(function (error) {
        console.log('No se ha podido conectar con el servidor o el path no existe');
      });
    } else {
      setData(
        desplegableoptions.map((item) => {
          return {
            name: inputProps.name,
            value: item.name,
            label: item.name,
          };
        })
      );
    }
  }, [data_apiroute, inputProps.name, desplegableoptions]);
  return (
    <Select
      className="Select"
      {...inputProps}
      onChange={onChange}
      options={data}
      styles={{
        control: (provided) => ({
          ...provided,
          border: "2px solid #e1e1e1",
          borderRadius: 6,
          boxShadow: "none",
          padding: "6px",
          "&:hover": {
            border: "2px solid black",
          },
        }),
      }}
    />
  );
}
export default Desplegable;
