import OverlayStyle from "./styles/OverlayStyle.js";

const Overlay = ({ showOverlay, setShowOverlay, ...props }) => {
  const estructura = props.estructura.overlay;

  return (
    <>
      {showOverlay && (
        <OverlayStyle>
          <div className="ContenedorModal">
            {/* <div className="Encabezado">
              <h1>{estructura.titulo}</h1>
            </div> */}
            <div
              className="BotonCerrar"
              onClick={() => {
                setShowOverlay(!showOverlay);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                class="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </div>
            <p>
              {estructura.cuerpo}
            </p>
            <div className="CentrarBoton">
              <button
                className="BotonAceptar"
                onClick={() => (window.location.href = "http://www.google.es")}
              >
                Aceptar
              </button>
            </div>
          </div>
        </OverlayStyle>
      )}
    </>
  );
};

//Crea un css que centre el div en la pantalla

export default Overlay;
