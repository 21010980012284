import Formulario from "./components/Formulario.jsx";
import "./App.css";
function App() {
  return (
    <div className="App">
      <Formulario />
    </div>
  );
}

export default App;
